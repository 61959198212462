import { useAppDispatch, useAppSelector } from 'hooks'
import {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { UrlParams } from 'reducers/types'
import { RootState } from 'reducers/store'
import './style.scss'
import moment from 'moment'
import { getSimulation } from 'reducers/simulations/thunks'
import { BroadcastMessage } from 'types/interfaces'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { resetTstTableData } from 'reducers/snci/slices'
import { isOldSimulation } from 'services/route'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { savefile } from 'utils'
import { downloadTst, getTstTable } from 'reducers/snci/thunks'
import SimpleButton, { ButtonStyle } from 'components/button'

export default function Tst() {
  const { selectedSimulation, webSocket } = useAppSelector((state: RootState) => state.simulations)
  const { tstTable, tstTableData } = useAppSelector(state => state.snci)
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (urlParams.id) {
      dispatch(getSimulation(urlParams.id))
    }
  }, [])

  useEffect(() => {
    if (selectedSimulation) {
      dispatch(getTstTable(selectedSimulation.scheduleId))
    }
  }, [selectedSimulation])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleDownload = () => () => {
    if (selectedSimulation) {
      dispatch(downloadTst(selectedSimulation.scheduleId))
    }
  }

  useEffect(() => {
    if (tstTableData) {
      savefile(tstTableData, `TST_${moment().format('DD/MM/YYYY')}`)
    }
    dispatch(resetTstTableData())
  }, [tstTableData])

  return (
    <div className="tst">
      <div className="toolbar">
        <SimpleButton
          style={ButtonStyle.primary}
          onClick={handleDownload()}
          text="Télécharger"
          sx={{ padding: '10px 24px 8px !important' }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tstTable?.headers.map((header: string) => (
                <StyledTableCell key={header}>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tstTable?.rows.map((row: any) => (
              <StyledTableRow key={row.join('-')}>
                {row.map((cell: any) => (
                  <StyledTableCell key={cell} align="left">
                    {cell}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
