/* eslint-disable max-len */
import {
  get,
} from '@osrdata/app_core/dist/requests'

import { createApiThunk } from 'utils'
import {
  setSnciHtml,
  setTstTable,
  setTstTableData,
} from './slices'

const getHtml = () => createApiThunk(
  'snci/getHtml',
  () => get('/masters/static/interfaces/snci'),
  setSnciHtml,
)

const getTstTable = (scheduleId: string) => createApiThunk(
  'snci/getTstTable',
  () => get(`/masters/schedules/${scheduleId}/tst/`),
  setTstTable,
)

const downloadTst = (scheduleId: string) => createApiThunk(
  'snci/downloadTst',
  () => get(`/masters/schedules/${scheduleId}/tst-download/`, {}, undefined, { responseType: 'blob' }),
  setTstTableData,
)

export {
  getHtml as getSnciHtml,
  getTstTable,
  downloadTst,
}
