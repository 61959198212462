import { AppDispatch } from 'reducers/store'
import { Filter } from 'types/filters'
import { LogEntry } from 'types/logs'
import { SocketMessage } from 'types/websocket'

// todo split types
export type DispatchFunction = (dispatch: AppDispatch) => void

export type UnknownObject = { [k: string]: unknown }

export type ResponseError = {
  status: number;
  message: UnknownObject;
}

export interface Version {
  name: string
  id: string
}

export interface Network {
  name: string
  id: string
}

export interface Schedule {
  name: string
  id: string
}

export interface Simulation {
  id: string,
  name: string,
  severity: string,
  status: string,
  incrementalNumber: number
  userId: string
  scheduleId: string
  scenario: {
    versionId: string,
    versionName: string,
    networkId: string,
    networkName: string,
    scheduleId: string,
    scheduleName: string,
  },
  createdAt: string,
  comments: Comment[],
  logs: LogEntry[],
  initialTime: string;
}

export interface Feedback {
  content: string;
  logs: string[];
  severity: Severity;
  status: Status;
}
export interface GetSimulationsParams {
  versionId?: string
  ordering?: string
  page_size?: number
  page?: number
  filters?: Filter[]
}

export interface Comment {
  id: string,
  userId: string,
  incrementalNumber: 1,
  content: string,
  status: null,
  severity: null,
  logs: string[],
  createdAt: string,
  updatedAt: string
}

export interface Status {
  content: string
  level: number
}

export interface Severity {
  content: string
  level: number
}

export type PaginatedResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface userInfo {
  id: string
  firstName: string
  lastName: string
  username: string
  displayName: string
}

export type UrlParams = {
  id: string;
  interface: 'tco' | 'tpt'
  name: string;
  mode?: 'static';
}

export enum SimulationsState {
  started = 'started',
  paused = 'paused',
  stopped = 'stopped'
}

export type UserSub = {
  userId: string;
}

export type SvgStream = {[key:string]: {[key:string]: string}};

export type ShortBeep = {
  timestamp: string;
  waveType: string;
  frequency: number;
  duration: number;
  messageType: SocketMessage.shortBeep | SocketMessage.longBeep;
}

export type LongBeep = {
  timestamp: string;
  waveType: string;
  frequency: number;
  state: boolean;
  messageType: SocketMessage.shortBeep | SocketMessage.longBeep;
}

export type TstTable = {
  headers: string[];
  rows: string[][];
}
