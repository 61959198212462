import { ReactElement } from 'react'
import Button from '@mui/material/Button'

import './style.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
  secondary = 'secondary',
  rounded = 'rounded',
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  sx?: object;
}

export default function SimpleButton({
  text, onClick, style, disabled, startIcon, endIcon, sx,
}: Props): ReactElement {
  return (
    <Button
      className={`simple-button button--${style}`}
      variant="contained"
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={sx}
    >
      {text}
    </Button>
  )
}

const defaultProps = {
  style: ButtonStyle.primary,
  disabled: false,
  startIcon: '',
  endIcon: '',
  sx: {},
  onClick: () => { /* to implement */ },
}

SimpleButton.defaultProps = defaultProps
