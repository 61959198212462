/* eslint-disable jsx-a11y/no-autofocus */
import { modalSignal } from 'components/modals/wrapper'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  useCallback, useEffect, useRef,
  useState,
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { getSimulation } from 'reducers/simulations/thunks'
import { RootState } from 'reducers/store'
import { UrlParams } from 'reducers/types'
import { BroadcastMessage } from 'types/interfaces'
import SimpleButton, { ButtonStyle } from 'components/button'
import SimulationStream from 'components/simulationStream'
import { getSnciHtml } from 'reducers/snci/thunks'
import { isOldSimulation } from 'services/route'
import { SocketMessage } from 'types/websocket'
import { isDevEnv } from 'utils'
import './style.scss'
import AudioPlayer from 'components/audioPlayer'

export default function Snci() {
  const dispatch = useAppDispatch()
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const { selectedSimulation, webSocket } = useAppSelector((state: RootState) => state.simulations)
  const { stream, html, beep } = useAppSelector((state: RootState) => state.snci)
  const hiddenInput = useRef(null)
  const iframe = useRef(null)
  const [userHasInteracted, setInteraction] = useState(false)
  const [queryParams] = useSearchParams()
  const isHistory = queryParams.get('mode') === 'history'

  const confirmClose = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const sendCloseMessage = useCallback(() => {
    broadcastRef?.current.postMessage({ type: BroadcastMessage.closeSnci })
  }, [])

  useEffect(() => {
    if (!stream) return
    const iframeInterface = document.getElementById('iframe-wrapper') as HTMLIFrameElement
    const iframeDocument = iframeInterface.contentDocument
    Object.entries(stream).forEach(([key, value]) => {
      const element = iframeDocument.getElementById(key)
      const properties = Object.keys(value)
      if (element) {
        properties.forEach(prop => {
          if (prop === 'innerHTML') {
            element.innerHTML = value[prop]
            return
          }
          element.setAttribute(prop, value[prop])
        })
      }
    })
  }, [stream])

  useEffect(() => {
    if (urlParams.id && !broadcastRef.current) {
      const bc = new BroadcastChannel(urlParams.id)

      bc.postMessage({ type: BroadcastMessage.openSnci })

      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage.closeSnci) {
          window.close()
        }
        if (event.data.type === BroadcastMessage.updateName) {
          dispatch(setSelectedSimulation({
            ...selectedSimulation,
            name: event.data.message,
          }))
        }
      }

      broadcastRef.current = bc

      dispatch(getSimulation(urlParams.id))
    }

    if (isOldSimulation(urlParams)) {
      window.addEventListener('beforeunload', sendCloseMessage)
    } else {
      window.addEventListener('beforeunload', confirmClose)
      window.addEventListener('unload', sendCloseMessage)
    }

    return () => {
      window.removeEventListener('beforeunload', confirmClose)
      window.removeEventListener('unload', sendCloseMessage)
      window.removeEventListener('beforeunload', sendCloseMessage)
    }
  }, [selectedSimulation])

  useEffect(() => {
    if (isOldSimulation(urlParams)) return

    if (modalSignal.value) {
      window.addEventListener('beforeunload', sendCloseMessage)
      window.removeEventListener('beforeunload', confirmClose)
    } else {
      window.removeEventListener('beforeunload', sendCloseMessage)
      window.addEventListener('beforeunload', confirmClose)
    }
  }, [modalSignal.value])

  // fix to add custom fonts to iframe
  const loadCustomFonts = () => {
    const frame = iframe.current as HTMLIFrameElement
    const newFont = new FontFace('UnifontExMono', 'url(/fonts/UnifontExMono.woff)')
    newFont.load().then(font => {
      frame.contentDocument.fonts.add(font)
    }).catch(() => {
      //
    })
  }

  useEffect(() => {
    dispatch(getSnciHtml())
    const frame = iframe.current as HTMLIFrameElement

    const interactWithWindow = () => {
      setInteraction(true)
    }
    const hideElements = () => {
      if (!isDevEnv()) {
        const gearIcon = frame.contentDocument.getElementById('gearIcon')
        if (gearIcon) {
          gearIcon.style.display = 'none'
        }
      }
    }

    const loadHandler = () => {
      loadCustomFonts()
      hideElements()
      frame.contentWindow.addEventListener('focus', () => {
        setTimeout(() => {
          hiddenInput?.current?.focus()
        }, 0)
      })

      // events to trigger sound when user has interacted with the app
      frame.contentWindow.addEventListener('pointerdown', () => {
        interactWithWindow()
      }, { once: true })

      hiddenInput.current.addEventListener('keydown', () => {
        interactWithWindow()
      }, { once: true })

      window.addEventListener('pointerdown', interactWithWindow, { once: true })

      return () => {
        frame.contentDocument.removeEventListener('pointerdown', () => {
          hiddenInput?.current?.focus()
          interactWithWindow()
        })
      }
    }

    frame.addEventListener('load', loadHandler)
    return () => {
      frame.removeEventListener('load', loadHandler)
    }
  }, [])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    webSocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.keyPress,
      key: event.key,
    }))
  }

  const openInterface = (screen: string) => () => {
    const params = isHistory ? '/static' : ''
    window.open(
      `${window.location.origin}/${screen}/${selectedSimulation.id}${params}`,
      '',
      `width=${window.innerWidth},height=${window.screen.availHeight}, left=${window.screen.availWidth}`,
    )
  }

  return (
    <div
      className="snci"
      onClick={() => hiddenInput?.current?.focus()}
    >
      <div className="toolbar">
        <SimpleButton
          style={ButtonStyle.rounded}
          onClick={openInterface('tst')}
          text="TST"
          sx={{ padding: '10px 24px 8px !important' }}
        />
      </div>

      <input
        autoFocus
        ref={hiddenInput}
        onKeyDown={handleKeyPress}
        className="hidden-input"
      />
      <iframe
        ref={iframe}
        title="snci"
        srcDoc={html}
        id="iframe-wrapper"
      />
      <AudioPlayer beep={beep} canPlay={userHasInteracted} />

      <SimulationStream type="snci" />
    </div>
  )
}
